
import { defineComponent, reactive } from "vue";

export default defineComponent({
  name: "Reset Password Completed",
  props: {
    userName: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const state = reactive({
      userName: props.userName,
    });

    return { state };
  },
});
